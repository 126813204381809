import { t } from '../../../src/common/i18n'
import route from '../../../src/common/utils/route'

import neumaticoIcon from '../../../src/common/components/home/icons/neumatico-coche-gris.svg'
import neumaticoIconHover from '../../../src/common/components/home/icons/neumatico-coche-amarillo.svg'

import revCocheIcon from '../../../src/common/components/home/icons/rev-coche-gris.svg'
import revCocheIconHover from '../../../src/common/components/home/icons/rev-coche-amarillo.svg'

import oilIcon from '../../../src/common/components/home/icons/aceite-gris.svg'
import oilIconHover from '../../../src/common/components/home/icons/aceite-amarillo.svg'

import bateriasIcon from '../../../src/common/components/home/icons/bateria-gris.svg'
import bateriasIconHover from '../../../src/common/components/home/icons/bateria-amarillo.svg'

import acIcon from '../../../src/common/components/home/icons/aire-ac-gris.svg'
import acIconHover from '../../../src/common/components/home/icons/aire-ac-amarillo.svg'

import itvIcon from '../../../src/common/components/home/icons/itv-gris.svg'
import itvIconHover from '../../../src/common/components/home/icons/itv-amarillo.svg'

import ozonoIcon from '../../../src/common/components/home/icons/desinfeccion-ozono-gris.svg'
import ozonoIconHover from '../../../src/common/components/home/icons/desinfeccion-ozono-amarillo.svg'

import otrosIcon from '../../../src/common/components/home/icons/otros-servicios-gris.svg'
import otrosIconHover from '../../../src/common/components/home/icons/otros-servicios-amarillo.svg'

import neumaticoMotoIcon from '../../../src/common/components/home/icons/neumatico-moto-gris.svg'
import neumaticoMotoIconHover from '../../../src/common/components/home/icons/neumatico-moto-amarillo.svg'

import revMotoIcon from '../../../src/common/components/home/icons/rev-moto-gris.svg'
import revMotoIconHover from '../../../src/common/components/home/icons/rev-moto-amarillo.svg'

export const rodiHomeCarItems = [
  {
    id: 1,
    title: t('menuHeader.neumaticos'),
    icon: neumaticoIcon,
    iconHover: neumaticoIconHover,
    onPromotion: true,
    link: route('neumaticos.index'),
  },
  {
    id: 2,
    title: t('home.revision_cambio_aceite'),
    icon: revCocheIcon,
    iconHover: revCocheIconHover,
    link: route('revisiones.asistente.index'),
  },
  {
    id: 3,
    title: t('menuHeader.aceites'),
    icon: oilIcon,
    iconHover: oilIconHover,
    link: route('coche.aceites'),
  },
  {
    id: 4,
    title: t('menuHeader.baterias'),
    icon: bateriasIcon,
    iconHover: bateriasIconHover,
    link: route('coche.baterias'),
  },
  {
    id: 5,
    title: t('menuHeader.carga_ac'),
    icon: acIcon,
    iconHover: acIconHover,
    onPromotion: true,
    link: route('coche.carga'),
  },
  {
    id: 6,
    title: t('menuHeader.revision_preitv'),
    icon: itvIcon,
    iconHover: itvIconHover,
    link: route('revisiones.revision-pre-itv'),
  },
  {
    id: 7,
    title: t('menuHeader.desinfeccion_ozono'),
    icon: ozonoIcon,
    iconHover: ozonoIconHover,
    link: route('coche.desinfeccion_ozono'),
  },
  {
    id: 8,
    title: t('menuHeader.otros'),
    icon: otrosIcon,
    iconHover: otrosIconHover,
    link: route('coche.index'),
  },
]

export const rodiHomeMotoItems = [
  {
    id: 9,
    title: t('menuHeader.neumaticos_moto'),
    icon: neumaticoMotoIcon,
    iconHover: neumaticoMotoIconHover,
    link: route('landing-moto'),
  },
  {
    id: 10,
    title: t('menuHeader.revision_moto'),
    icon: revMotoIcon,
    iconHover: revMotoIconHover,
    link: route('moto.revisiones.index'),
  },
  {
    id: 11,
    title: t('menuHeader.otros'),
    icon: otrosIcon,
    iconHover: otrosIconHover,
    link: route('moto.index'),
  },
]
