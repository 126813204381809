import React, { useState } from 'react'
import styles from './HomeCard.module.scss'
import arrow from './icons/home_card_arrow.svg'
import { t } from '../../i18n'

const HomeCard = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <a href={item.link}>
      <div
        className={styles.card}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div
          className={`${styles.topSection} ${
            item.onPromotion ? styles.topSection_withPromocion : ''
          }`}>
          <div className={styles.titleWrapper}>
            <div className={styles.leftBorder}></div>
            <h3 className={styles.cardTitle}>{item.title}</h3>
          </div>
        </div>
        <div className={styles.bottomSection}>
          {item.onPromotion ? (
            <span className={styles.promotionLabel}>
              {t('home-grids.promocion')}
            </span>
          ) : (
            <span className={styles.promotionPlaceholder}></span>
          )}
          <div className={styles.iconArrowWrapper}>
            <img
              src={isHovered ? item.iconHover : item.icon}
              alt="Icon"
              className={styles.icon}
            />
            <img src={arrow} alt="Ir" className={styles.arrow} />
          </div>
        </div>
      </div>
    </a>
  )
}

export default HomeCard
