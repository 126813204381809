import Neumatico from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../assets/neumatico-selector.png'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Next from '../../../assets/images/Talleres/next.svg'
import { SelectorState } from '../../../context/reducers/selectorReducer'
import { t } from '../../../../common/i18n'
import Button from '../../buttons/Button'
import ModalInfoMedidas from '../Common/ModalInfoMedidas/ModalInfoMedidas'
import SelectorDropDown from '../Desktop/Components/SelectorDropDown'
import Step, { findNext, InSkipInfo } from '../SelectorSteps'
import styles from './selectorHome.module.scss'
import NeumaticoIcon from '../../../assets/home/neumatico_home.svg'

interface SelectorHomeProps {
  selectorState: SelectorState
  dispatch: (action: any) => void
  handleChange: (e: any) => void
  isValid: (state: SelectorState) => boolean
  skipInfo: InSkipInfo
  isNewHome: boolean
}

const SelectorHome = ({
  selectorState,
  skipInfo,
  handleChange,
  isValid,
  isNewHome = false,
}: SelectorHomeProps) => {
  const [showModalMoreInfo, setShowModalMoreInfo] = useState(false)
  const [showArrow, setShowArrow] = useState(null)

  const openModal = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    setShowModalMoreInfo(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!isValid(selectorState)) {
      return
    }

    const step = findNext(Step.INITIAL, 'desktop', skipInfo)
    navigate(step.routes.desktop)
  }

  const onMenuOpen = (id) => {
    setShowArrow(id)
  }

  const onMenuClose = () => {
    setShowArrow(null)
  }

  return (
    <div
      className={
        styles[`selector_home_container${isNewHome ? '_isNewHome' : ''}`]
      }>
      <div className={styles.neumatico_wrapper}>
        {process.env.GATSBY_WEB === 'rodi' && (
          <div className={styles.title_wrapper}>
            <NeumaticoIcon className={styles.neumatico_icon} />
            <h2> {t('selector-desktop.encontrar_mis_neumaticos')}</h2>
          </div>
        )}
        <img
          src={Neumatico}
          alt="Neumático genérico"
          className={styles.neumatico}
          width="450"
          height="133"
        />
        <a href="#" className={styles.no_data} onClick={openModal}>
          <FormattedMessage id="cesta.general.no_info" />
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.row}>
          <div className={styles.form_ancho}>
            {selectorState.ancho.value && (
              <span className={styles.anchoSpan}>
                {selectorState.ancho.value} /
              </span>
            )}
            {!selectorState.ancho.value && (
              <span className={`${styles.anchoSpan} ${styles.defaultValue}`}>
                205 /
              </span>
            )}
            {showArrow === 'ancho' && (
              <div className={styles.arrowAncho}>
                <div className={styles.firstPart} />
                <div className={styles.secondPart} />
                <div className={styles.thirdPart} />
              </div>
            )}
            <SelectorDropDown
              required
              sorted
              size="xl"
              name="ancho"
              field={selectorState.ancho}
              onChange={handleChange}
              labelId="selector-desktop.ancho"
              labelAbove={true}
              onFocus={() => onMenuOpen('ancho')}
              onBlur={() => onMenuClose()}
            />
          </div>

          <div className={styles.form_serie}>
            {selectorState.serie.value && (
              <span className={styles.serieSpan}>
                {selectorState.serie.value}
              </span>
            )}
            {!selectorState.serie.value && (
              <span className={`${styles.serieSpan} ${styles.defaultValue}`}>
                55
              </span>
            )}
            {showArrow === 'serie' && (
              <div className={styles.arrowSerie}>
                <div className={styles.firstPart} />
                <div className={styles.secondPart} />
                <div className={styles.thirdPart} />
              </div>
            )}
            <SelectorDropDown
              required
              size="xl"
              name="serie"
              field={selectorState.serie}
              onChange={handleChange}
              labelId="selector-desktop.serie"
              labelAbove={true}
              onFocus={() => onMenuOpen('serie')}
              onBlur={() => onMenuClose()}
            />
          </div>

          <div className={styles.form_llanta}>
            {selectorState.llanta.value && (
              <span className={styles.llantaSpan}>
                R {selectorState.llanta.value}
              </span>
            )}
            {!selectorState.llanta.value && (
              <span className={`${styles.llantaSpan} ${styles.defaultValue}`}>
                R 16
              </span>
            )}
            {showArrow === 'llanta' && (
              <div className={styles.arrowLlanta}>
                <div className={styles.firstPart} />
                <div className={styles.secondPart} />
                <div className={styles.thirdPart} />
              </div>
            )}
            <SelectorDropDown
              required
              size="xl"
              name="llanta"
              field={selectorState.llanta}
              onChange={handleChange}
              labelId="selector-desktop.llanta"
              labelAbove={true}
              onFocus={() => onMenuOpen('llanta')}
              onBlur={() => onMenuClose()}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.form_ic}>
            {selectorState.carga.value && (
              <span className={styles.icSpan}>{selectorState.carga.value}</span>
            )}
            {!selectorState.carga.value && (
              <span className={`${styles.icSpan} ${styles.defaultValue}`}>
                91
              </span>
            )}
            {showArrow === 'carga' && (
              <div className={styles.arrowIc}>
                <div className={styles.firstPart} />
                <div className={styles.secondPart} />
                <div className={styles.thirdPart} />
              </div>
            )}
            <SelectorDropDown
              required
              size="xl"
              name="carga"
              field={selectorState.carga}
              onChange={handleChange}
              labelId="selector-desktop.indice-carga"
              labelAbove={true}
              infoIconTitle={t('selector-desktop.indice-carga')}
              infoIconText={t('selector-desktop.indice_carga_help_text')}
              onFocus={() => onMenuOpen('carga')}
              onBlur={() => onMenuClose()}
            />
          </div>

          <div className={styles.form_cv}>
            {selectorState.velocidad.value && (
              <span className={styles.cvSpan}>
                {selectorState.velocidad.value}
              </span>
            )}
            {!selectorState.velocidad.value && (
              <span className={`${styles.cvSpan} ${styles.defaultValue}`}>
                V
              </span>
            )}
            {showArrow === 'velocidad' && (
              <div className={styles.arrowCv}>
                <div className={styles.firstPart} />
                <div className={styles.secondPart} />
                <div className={styles.thirdPart} />
              </div>
            )}
            <SelectorDropDown
              required
              size="xl"
              name="velocidad"
              field={selectorState.velocidad}
              onChange={handleChange}
              labelId="selector-desktop.indice-velocidad"
              labelAbove={true}
              infoIconTitle={t('selector-desktop.indice-velocidad')}
              infoIconText={t('selector-desktop.indice_velocidad_help')}
              onFocus={() => onMenuOpen('velocidad')}
              onBlur={() => onMenuClose()}
            />
          </div>
        </div>
      </form>

      <div className={styles.button_wrapper}>
        <Button
          disabled={!isValid(selectorState)}
          buttonClass="next_button"
          onClick={handleSubmit}>
          <FormattedMessage id="selector-desktop.buscar" />
          <Next />
        </Button>
      </div>
      <ModalInfoMedidas
        showModalMoreInfo={showModalMoreInfo}
        setShowModalMoreInfo={setShowModalMoreInfo}
      />
    </div>
  )
}

export default SelectorHome
