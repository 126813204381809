import React from 'react'
import GridLayout from './HomeGridLayout'
import Card from './HomeCard'

const HomeSection = ({ title, items }) => (
  <GridLayout title={title}>
    {items.map((item) => (
      <Card key={item.id} item={item} />
    ))}
  </GridLayout>
)

export default HomeSection
