import React from 'react'
import styles from './HomeNavSection.module.scss'
import SelectorContainer from '../../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import route from '../../../../src/common/utils/route'
import SelectorHome from '../../../../src/common/components/FunnelSelector/Entrada/SelectorHome'
import HomeSection from '../../../../src/common/components/home/HomeSection'
import { t } from '../../../../src/common/i18n'
import {
  rodiHomeCarItems,
  rodiHomeMotoItems,
} from '../../constants/homeCardItems'
import SearchNeumaticos from '../../../../src/common/components/searchNeumaticosCardHome'

const HomeNavSection = () => {
  return (
    <div className={styles.new_home_wrapper}>
      <div className={styles.new_home_selector_wrapper}>
        <SelectorContainer showMarcas={false} origin={route('index')}>
          {({ ...props }) => <SelectorHome {...props} isNewHome={true} />}
        </SelectorContainer>
      </div>
      <SearchNeumaticos />
      <HomeSection title={t('home-grids.coche')} items={rodiHomeCarItems} />
      <HomeSection title={t('home-grids.moto')} items={rodiHomeMotoItems} />
    </div>
  )
}

export default HomeNavSection
