import React from 'react'
import styles from './HomeGridLayout.module.scss'

const HomeGridLayout = ({ title, children }) => (
  <section className={styles.gridSection}>
    <h2 className={styles.sectionTitle}>{title}</h2>
    <div className={styles.gridContainer}>{children}</div>
  </section>
)

export default HomeGridLayout
